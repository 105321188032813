import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '../pure';
import WaveEffect from '../formElements/WaveEffect';
import MoreOptions from '../formElements/MoreOptions';
import sAction from 'sAction';
import NewActivityButton from './NewActivityButton';
import ListViewPagination from '../list/ListViewPagination';
import CheckBox from 'ROOT/src/components/formElements/CheckBox';
import getSubpanelHeaderName from 'ROOT/src/action/subpanel/getSubpanelHeaderName';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class SubpanelHeader extends PureComponent {
    constructor(props) {
        super(props);
        this.header = React.createRef();
        const parentID = sAction.dataGet(props.prefix+'/id');
        const currentDetail = sAction.dataGet('view/module');
        let thisSubData = 0;
        if (currentDetail === 'Accounts') {
            const relName = this.props.data.def.get('rel').get('relName');

            const lsData = sAction.getStorage('Accounts-subpanel-branch');
            if (lsData?.[relName]) {
                thisSubData = lsData[relName]; // stage of checkbox after load
            } else if (!lsData) {
                sAction.setStorage('Accounts-subpanel-branch', {}); // no required any more, if we want some default values
            }
        }
        this.state = {
            showData: sAction.isMobile() ? true : thisSubData, // get data for specific subpanel
            inModule: currentDetail, // current detailview what we are in
            parentID: parentID, //
            isMaxSize: false,
        };
    }
    /**
     * handle list view pagination for subpanel
     * @param {string} type list type
     */
    pagination(type) {
        const data = this.props.data;
        const listData = data.rows;
        const way = this.props.way;
        const paginationData = {
            offset: listData.offset,
            limit: listData.limit,
            rowCount: listData.rowCount,
            page: listData.page,
            prefix: way + '/rows',
            type: type,
        };
        sAction.listPagination(paginationData);
    }
    /**
     * handle subpanel max size
     */
    subpanelMaxSize() {
        const way = this.props.way;
        const container = this.header.current.closest('.subpanelContainer');
        if (container.classList.contains('maxSize')) {
            this.setState({isOpen: '', isMaxSize: false});
            container.classList.remove('maxSize');
            sAction.dataSet(way + '/rows/limit', 10);
            sAction.reloadList(0, 1, way + '/rows');
        } else {
            container.classList.add('maxSize');
            this.setState({isMaxSize: true});
            sAction.dsClear();
            sAction.dsAdd('set', way + '/rows/limit', 100);
            sAction.dsAdd('set', way + '/rows/selected', []);
            sAction.dsProcess();
            sAction.reloadList(0, 1, way + '/rows');
        }
    }
    /**
     *
     */
    openRelateListView() {
        let data = this.props.data;

        const defaultFilter = sAction.subpanelGenerateDefaultFilter(
            this.props.prefix,
            data,
        );
        const paramData = {
            module: data.module,
            defaultFilter: defaultFilter,
            moreOptions: data?.moreOptions,
        };
        if ((
            data.module === 'History' &&
            data.def.getIn(['rel', 'module']) === 'Calls'
        ) || (
            data.module === 'History' &&
            data.def.getIn(['rel', 'module']) === 'Meetings'
        )) {
            data = data.setIn(['def', 'rel', 'relName'], 'notes');
            data = data.setIn(['rows', 'modul'], 'Notes');
            data = data.setIn(['rows', 'defaultFilter', 'subpanel', 'relName'], 'Notes');
            paramData.module = 'Notes';
        }
        sAction.openRelatePopup(paramData, (returnData) => {
            sAction.subppanelChangeRelate(
                returnData,
                data,
                this.props.prefix,
                this.props.way,
                0,
            );
        });
    }

    /**
     * open export menu popup
     */
    openExportMenu() {
        const prefix = this.props.way + '/rows';
        const data = this.props.data;
        const params = {
            prefix: prefix,
            module: data.module,
        };
        if (data.module === 'Activities' || data.module === 'History') {
            sAction.exportActivitiesOrHistoryList();
        } else {
            sAction.exportList(params);
        }
    }

    /**
     * close subpanel
     */
    closeMaxSize() {
        if (this.state) {
            const container = this.header.current.closest('.subpanelContainer');
            if (container.classList.contains('maxSize')) {
                container.classList.remove('maxSize');
            }
        }
    }

    /**
     * add new file revision to document
     */
    subpanelNewRevision() {
        sAction.filesNewVersion({
            prefix: this.props.prefix,
            module: 'Documents',
            id: sAction.dataGet(`${this.props.prefix}/id`),
            name: sAction.dataGet(`${this.props.prefix}/name`),
        });
    }

    /**
     * add new record to subpanel module
     */
    subpanelNewRecord() {
        this.closeMaxSize();
        let data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        // if (data.module === 'History' && data.def.getIn(['rel', 'module']) === 'Calls' || data.module === 'History' &&
        //     data.def.getIn(['rel', 'module']) === 'Meetings') {
        //     data = data.setIn(['module'], 'Notes');
        //     data = data.setIn(['def', 'rel', 'relName'], 'notes'); // notes
        // }
        sAction.subpanelNewRecord({paramData: data, way, prefix});
    }

    /**
    * Get new data in subpanel
    * @param {Object} state
    **/
    getChildrenData(state) {
        const relName = this.props.data.def.get('rel').get('relName');
        const lsData = sAction.getStorage('Accounts-subpanel-branch');

        lsData[relName] = state?1:0;

        this.setState({showData: state}); // for checkbox
        sAction.setStorage('Accounts-subpanel-branch', lsData); // for downloading data

        const reloadData = {
            module: this.props.data.module,
            parentId: this.state.parentID,
            parentModule: this.props.data.def.get('rel').get('module'),
            prefix: this.props.prefix,
            relName: relName,
            way: this.props.way,
        };

        sAction.reloadSubpanel(reloadData); // fetch new data
        sAction.route(); // it is necessary to reload, otherwise it will not reload visible lines in subpanel
    }

    /**
     * reload subpanel
     */
    optionsReloadSubpanel() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const module = data.module;
        const open = data.def.get('open');
        if (open) {
            sAction.reloadSubpanelByWay(prefix, module);
        } else {
            sAction.listViewCount(`${way}/rows`);
        }
    }

    /**
     * open listview edit
     */
    optionsListviewEdit() {
        sAction.openDefaulListViewEdit(`${this.props.way}/rows`);
    }

    /**
     * optionResetDefaultOrder
     */
    optionResetDefaultOrder() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;

        const subpanelData = {
            module: data.def.getIn(['rel', 'module']),
            subpanelModule: data.module,
        };

        sAction.resetSubpanelPreferences(subpanelData);
        sAction.toogleSubpanel(data, way, prefix);

        setTimeout(() => {
            const freshData = sAction.dataGet(way);
            sAction.toogleSubpanel(freshData, way, prefix);
        }, 800);
    }

    render() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const records = this.props.records;
        // const pageNumber = data.rows.page;
        const open = data.def.get('open');
        const readonly = data.def.get('readonly');
        const listData = data.rows;
        const moreOptions = this.props.data.moreOptions?.toJS?.();
        let icon;
        const module = data.module;
        const menuGroups = sAction.dataGet('menu/groups');
        for (const group of menuGroups) {
            icon = group.icon;
            if (icon !== module) {
                icon = group.buttons.find((button) => button.name === module);
                icon = icon === undefined ? 'object-connection' : icon.name;
            }

            if (icon === module) {
                break;
            }
        }

        if (module === 'History') {
            icon = 'history';
        }

        if (module && module.includes('mail')) {
            icon = 'emails';
        }

        if (/user/i.test(module)) {
            icon = 'Users';
        }

        let subpanelIconClass = 'icon-subpanelClose';
        let subpanelIconClassTranslate = 'LBL_MINIMIZE';
        if (open === false) {
            subpanelIconClass = 'icon-subpanelOpen';
            subpanelIconClassTranslate = 'LBL_MAXIMIZE';
            // sAction.toogleSubpanel(data, way, prefix);
        }
        let showActivities;
        const addOptions = [];
        if (data.module === 'Activities' || data.module === 'History') {
            // if (data.def.getIn(['rel', 'module']) !== 'Calls' && data.def.getIn(['rel', 'module']) !== 'Meetings') {
            // deprecated now is allowed activity timeline for calls and meetings wich shows activity history of account from relate
            showActivities = (
                <TooltipWrapper label={'LBL_ACTIVITY_TIMELINE'}>
                    <div
                        className="subpanelHeaderRightButton icon-Activities"
                        onClick={() => {
                            this.closeMaxSize();
                            sAction.showActivitiesPanel(prefix);
                        }}
                    />
                </TooltipWrapper>
            );
        }

        let newRecordButton;
        if (readonly) {
            newRecordButton = null;
        } else if (data.module === 'Activities') {
            newRecordButton = (
                <NewActivityButton
                    data={data}
                    parent={this.header.current ? this : ''}
                    prefix={prefix}
                    way={way}
                    activities={[
                        {module: 'Meetings'},
                        {module: 'Calls'},
                        {module: 'Tasks'},
                        {
                            label: sAction.translate('LBL_COMPOSE_EMAIL_BUTTON_LABEL'),
                            module: 'Emails',
                            icon: 'icon-Emails',
                        },
                    ]}
                />
            );
        } else if (
            data.module === 'History' // &&
            // data.def.getIn(['rel', 'module']) !== 'Calls' &&
            // data.def.getIn(['rel', 'module']) !== 'Meetings'
        ) {
            newRecordButton = (
                <NewActivityButton
                    parent={this.header.current ? this : ''}
                    data={data}
                    prefix={prefix}
                    way={way}
                    activities={[
                        {
                            label: sAction.translate('LBL_MODULE_NAME_SINGULAR_NEW', 'Emails'),
                            module: 'Emails',
                            icon: 'icon-Emails',
                        }, {module: 'Notes'}]}
                />
            );
        } else {
            if (sAction.hasAccess(data.module, 'newRecord') === true && !(moreOptions?.create_new === false)) {
                addOptions.push({
                    label: sAction.translate('LBL_CREATE_NEW'),
                    value: data.module === 'DocumentRevisions' ? 'subpanelNewRevision' : 'subpanelNewRecord',
                    icon: 'icon-addIcon',
                });
            }
        }
        const childRecordsButton = [];
        let options = [];
        if (data.module === 'Activities' ||
            data.module === 'History' && data.def.getIn(['rel', 'module']) !== 'Calls' &&
            data.def.getIn(['rel', 'module']) !== 'Meetings'
        ) {
            options = [
                {
                    label: sAction.translate('LBL_EXPORT_LIST'),
                    value: 'openExportMenu',
                    icon: 'icon-download',
                },
            ];
        } else {
            if (this.state.inModule === 'Accounts') {
                childRecordsButton.push(
                    <TooltipWrapper key="branchOffices" label={'LBL_SHOW_HQ_DATA_BUTTON'}>
                        <div>
                            <CheckBox
                                defaultValue={this.state.showData ? 1 : 0}
                                onChange={(e) => this.getChildrenData(e)}
                            />
                        </div>
                    </TooltipWrapper>,
                );
            }

            if (!readonly && data.module !== 'DocumentRevisions' && data.module !== 'History' && !(moreOptions?.select_existing === false)) {
                addOptions.push({
                    label: sAction.translate('LBL_ASSIGN'),
                    value: 'openRelateListView',
                    icon: 'icon-listview',
                });
            }
            if (sAction.hasAccess(data.module, 'export')) {
                options.push({
                    label: sAction.translate('LBL_SUBPANEL_UPDATE'),
                    value: 'optionsReloadSubpanel',
                    icon: 'icon-refresh',
                });
            }
            if (sAction.canAccessEditView()) {
                options.push({
                    label: sAction.translate('LBL_SUBPANEL_EDIT'),
                    value: 'optionsListviewEdit',
                    icon: 'icon-EditField',
                });
            }
            options.push({
                label: sAction.translate('LBL_SUBPANEL_RESET_DEFAULT_ORDER'),
                value: 'optionResetDefaultOrder',
                icon: 'iconfas-ResetOrder',
            });
        }

        const subpanelName = getSubpanelHeaderName(data);

        let rowTotalCountComp = null;
        if (open === false || listData.rowCount === 0 ) {
            rowTotalCountComp = (
                <TooltipWrapper label={'LBL_ALL_RECORDS'}>
                    <div>{listData.rowTotalCount ?? 0}</div>
                </TooltipWrapper>
            );
        }

        const recordsPerSubpanel = sAction.dataGet('conf/user/records_per_subpanel');

        // const open = data.def.get('open');

        return (
            <div className="subpanelHeader" ref={this.header}>
                <div>
                    <div
                        className="subpanelHeaderLeftContainer"
                        style={{backgroundColor: this.props.iconColor}}
                    >
                        <TooltipWrapper label={'LBL_MOVE_PANEL'}>
                            {
                                // render dragHandle only if subpanel is first in the list (all tab)
                                this.props.activeSubpanelTab === 0 ?
                                    <span className="moveHolder icon-Sortable" {...this.props.drag} /> :
                                    <></>
                            }
                        </TooltipWrapper>
                        <div className={'subpanelIconContainer'}>
                            <span className={'icon-' + icon + ' subpanelIcon'}/>
                        </div>
                    </div>
                    <h3>{subpanelName}</h3>
                    {sAction.getStorage('debug') && sAction.isAcmAdmin() && data.module !== 'Activities' && data.module !==
                        'History' &&
                        (<div
                            className="debugDbButton icon-database"
                            onClick={() => sAction.generateDbLink({action: 'subpanel_table', subpanel: data.getIn(['def', 'rel'])})}
                        />
                        )
                    }
                </div>
                <div>
                    {data.def.get('open') === true &&
                        (+listData.page !== 1 || records !== 0
                        ) && (
                        <React.Fragment>
                            <ListViewPagination
                                offset={listData.offset}
                                limit={recordsPerSubpanel ? parseInt(recordsPerSubpanel) : listData.limit}
                                rowCount={listData.rowCount}
                                rowTotalCount={listData.rowTotalCount}
                                page={listData.page}
                                prefix={way + '/rows'}
                                module={data.module}
                                isMaxSize={this.state &&
                                        this.state.isMaxSize}
                            />
                            <TooltipWrapper label={this.state.isMaxSize ? 'LBL_MINIMIZE' : 'LBL_MAXIMIZE'}>
                                <div
                                    onClick={() => this.subpanelMaxSize()}
                                    className="subpanelHeaderRightButton icon-MaxSize"
                                />
                            </TooltipWrapper>
                        </React.Fragment>
                    )}
                    {rowTotalCountComp}
                    {data.module === 'Activities' && newRecordButton}
                    {data.module === 'History' && newRecordButton}
                    {!(['History', 'Activities'].includes(data.module)) && childRecordsButton}

                    {addOptions.length !== 0 && (
                        <MoreOptions
                            options={addOptions}
                            onRight={true}
                            fromRight={90}
                            onClick={(e, val) => this[val](e)}
                        >
                            <TooltipWrapper label={'LBL_ADD_RECORD'}>
                                <div className="subpnaelheaderNewRecord subpanelHeaderRightButton icon-addIcon">
                                    <WaveEffect className="dark"/>
                                </div>
                            </TooltipWrapper>
                        </MoreOptions>
                    )}
                    {showActivities}
                    <MoreOptions
                        options={options}
                        onRight={true}
                        fromRight={70}
                        onClick={(e, val) => this[val](e)}
                    >
                        <TooltipWrapper label={'LBL_MORE_OPTIONS'}>
                            <div className="subpanelHeaderRightButton icon-More"/>
                        </TooltipWrapper>
                    </MoreOptions>
                    {!this.state.isMaxSize &&
                        <TooltipWrapper label={subpanelIconClassTranslate}>
                            <div
                                onClick={() => sAction.toogleSubpanel(data, way, prefix)}
                                className={subpanelIconClass + ' toogleSubpanelButton'}
                            />
                        </TooltipWrapper>
                    }
                </div>
            </div>
        );
    }
}

SubpanelHeader.propTypes = {
    data: PropTypes.shape({
        def: PropTypes.shape({
            get: PropTypes.func,
            getIn: PropTypes.func,
            translations: ImmutablePropTypes.mapOf(
                PropTypes.string,
                PropTypes.string,
            ),
        }),
        getIn: PropTypes.func,
        setIn: PropTypes.func,
        module: PropTypes.string,
        moreOptions: PropTypes.shape({
            toJS: PropTypes.func,
        }),
        rows: PropTypes.shape({
            limit: PropTypes.any,
            offset: PropTypes.any,
            page: PropTypes.number,
            rowCount: PropTypes.any,
            rowTotalCount: PropTypes.any,
        }),
    }),
    iconColor: PropTypes.any,
    prefix: PropTypes.string,
    records: PropTypes.number,
    way: PropTypes.string,
    activeSubpanelTab: PropTypes.any,
    drag: PropTypes.any,
};
